<template>
  <addField
    :path="currentTable['tableUrl']"
    :additionalAttributes="additionalAttributes"
    :showDialog="openDialog"
    @dialog-closed="openDialog=false"
  >
    <v-btn text>{{ info.name }}</v-btn>
  </addField>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField },
  data() {
    return { openDialog: false };
  },
  props: ["info", "actionName", "currentTable"],
  computed: {
    additionalAttributes() {
      return { function: "getInsertedFieldsMulti", requestType: "ajax" };
    },
  },
};
</script>